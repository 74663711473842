import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useNavigate } from 'react-router';
import { Container, Row, Col, Form, Input, Label, Button} from 'reactstrap';
import { postMethod, postMethodService } from '../api';
import { Phone,Password,SignIn, SECRETKEY } from '../constants';
import Loader from '../layout/loader/LoaderLogin';
import SweetAlert from 'sweetalert2'
import { useDispatch, useSelector } from 'react-redux';
import { statusHandler } from '../store/slices/authSlice';
import { LOGIN_API, NOTI_LOGIN_API } from '../api/routes';
import { encodeAuth, encodeData } from '../utils/Helper';
import Cookies from 'js-cookie';

const Signin = () => {
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [phoneValidate,setPhoneValidate] = useState("");
  const [passwordValidate, setPasswordValidate] = useState("");
  const [loading, setLoading] = useState(false);
  const [togglePassword, setTogglePassword] = useState(false);
  const navigate = useNavigate();
  const {value} = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const [secretKey, setSecretKey] = useState('');
  const [secretKeyValidate, setSecretKeyValidate] = useState('');
  const [browserName, setBrowserName] = useState("Unknown Browser");
  const [deviceName, setDeviceName] = useState("Unknown Device");
  const [ip, setIp] = useState('');

  const  getDeviceAndBrowserInfo = () => {
    const userAgent = navigator.userAgent;
    // Determine the operating system
    if (userAgent.match(/Windows/i)) {
      setDeviceName("Windows");
    } else if (userAgent.match(/Mac OS/i)) {
      setDeviceName("Mac OS");
    } else if (userAgent.match(/Ubuntu/i)) {
      setDeviceName("Linux");
    } else if (userAgent.match(/Android/i)) {
      setDeviceName("Android");
    } else if (userAgent.match(/iOS/i)) {
      setDeviceName("iOS");
    }
  
    // Determine the browser
    if (userAgent.match(/Chrome/i)) {
      setBrowserName("Google Chrome");
    } else if (userAgent.match(/Firefox/i)) {
      setBrowserName("Firefox");
    } else if (userAgent.match(/Safari/i)) {
      setBrowserName("Apple Safari");
    } else if (userAgent.match(/Edge/i)) {
      setBrowserName("Microsoft Edge");
    } else if (userAgent.match(/Opera/i)) {
      setBrowserName("Opera");
    }
  };
  
  const fetchIp = async () => {
    const res = await fetch('https://api.ipify.org?format=json')
      .then(response => response.json()).then(data => data)
      setIp(res?.ip)
  }
  
  useEffect(() => {
    getDeviceAndBrowserInfo();
    fetchIp();
  }, []);


  const loginAuth = async (e) => {
    e.preventDefault();
    if(!phone){
      setPhoneValidate("Phone is required");
      return;
    }
    if (!password) {
      setPasswordValidate('Password is required');
      return;
    }
    if (!secretKey) {
      setSecretKeyValidate('SecretKey is required');
      return;
    }
    setLoading(true);
    const data = {
      name:phone,password,
      secret_key: secretKey,
      ip_address: ip,
      browser_name: browserName,
      device_name: deviceName,
    };
    const appData = {
      username: "admboss",
      password: "bingo@@123Noti",
    };
    let smsRes = await postMethodService(NOTI_LOGIN_API,appData);
      if(smsRes){
          if(smsRes.status === "success" || smsRes.status || smsRes.success){
              let saveInfo = {
                  token: smsRes?.data?.token,
              };
              const encoded = encodeAuth(saveInfo);
              Cookies.set("token", encoded);
              // -----------------
              const res = await postMethod(LOGIN_API, data);
              res && setLoading(false);
              if(res?.status === 'success'){
                dispatch(statusHandler(!value));
                  if(res.data.user_role){
                    if(res.data.user_role === 'admin'){
                        localStorage.setItem("y_g_n_d_a", encodeData(res.data));
                        navigate('/');
                        return
                    } else if(res.data.user_role === 'super'){
                        localStorage.setItem("y_g_n_d_a", encodeData(res.data));
                        navigate('/');
                        return
                    }else if(res.data.user_role === 'staff'){
                        localStorage.setItem("y_g_n_d_a", encodeData(res.data));
                        navigate('/admin/users');
                        return;
                    }else if(res.data.user_role === 'streamer'){
                        localStorage.setItem("y_g_n_d_a", encodeData(res.data));
                        navigate('/admin/users');
                        return;
                    }else if(res.data.user_role === 'partner'){
                        localStorage.setItem("y_g_n_d_a", encodeData(res.data));
                        navigate('/admin/users');
                        return;
                    }else{
                      SweetAlert.fire({
                          icon: 'error',
                          width: 300,
                          title: "Fail",
                          text: "You are not Admin!",
                      });
                      return;
                    }
                  };
              }else{
                SweetAlert.fire({
                  icon: 'error',
                  width: 300,
                  title: "Sorry!",
                  text: res?.message ?? 'Something wrong, Try again!',
              });
              };
              // -----------------
          };
      };
};
  return (
    <>
      <Container fluid={true} className="p-0">
        <Row>
          <Col xs="12">
            <div className="login-card">
              <div>
                <div className="login-main login-tab">
                    <Form className="theme-form">
                        <h4 style={{fontWeight: 'bolder'}}>{"Sign In"}</h4>
                        <p>{"Enter your name & password to login"}</p>
                        <div className="mb-3">
                            <Label className="col-form-label">{Phone}</Label>
                            <Input className="form-control" type="text" required onChange={e => {
                                setPhone(e.target.value);
                                setPhoneValidate("");
                            }} defaultValue={phone} placeholder="xyz..."/>
                            {
                              phoneValidate && <div className="invalid-feedback d-block">{phoneValidate}</div>
                            }
                        </div>
                        <div className="mb-3 position-relative">
                            <Label className="col-form-label">{Password}</Label>
                            <Input className="form-control" type={togglePassword ? "text" : "password"} onChange={e => {
                              setPassword(e.target.value);
                              setPasswordValidate("");
                            }} defaultValue={password} required/>
                            {
                              passwordValidate && <div className="invalid-feedback d-block">{passwordValidate}</div>
                            }
                            <div className="show-hide" onClick={() => setTogglePassword(!togglePassword)}><span className={togglePassword ? "" : "show"}></span></div>
                        </div>
                        <div className="mb-3 position-relative">
                            <Label className="col-form-label">{SECRETKEY}</Label>
                            <Input className="form-control" type={'text'} onChange={e => {
                              setSecretKey(e.target.value);
                              setSecretKeyValidate("");
                            }} defaultValue={secretKey} required/>
                            {
                              secretKeyValidate && <div className="invalid-feedback d-block">{secretKeyValidate}</div>
                            }
                        </div>
                        <div className="login-btn mb-0">
                            <Button type='submit' color="primary" disabled={loading ? loading : loading} onClick={(e) => loginAuth(e)}>{loading ? "LOADING..." : SignIn}</Button>
                        </div>
                    </Form>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      {
        createPortal(loading && <Loader />, document.getElementById('portal'))
      }
    </>
  )
}

export default Signin
