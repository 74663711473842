import {Users, UserPlus, Key, ArrowUpCircle, DollarSign, Clock, Folder, Dribbble, Monitor, Image, PenTool, Repeat, ArrowDownCircle, Home, Type, GitPullRequest, List, Gift, Phone, UserCheck, Star, Mail } from 'react-feather'

export const MENUITEMS = [
    {
        Items: [
            { path: `/admin/dashboard`, icon: Home, title: 'Dashboard', type: 'link' },
            // { path: `/admin/app-name`, icon: Tablet, title: 'App Name', type: 'link' },
            { path: `/admin/users`, icon: Users, title: 'Users', type: 'link' },
            { path: `/admin/create`, icon: UserPlus, title: 'Create User', type: 'link' },
            { path: `/admin/create-streamer`, icon: UserPlus, title: 'Create Streamer', type: 'link' },
            { path: `/admin/create-agent`, icon: UserCheck, title: 'Create Agent', type: 'link' },
            { path: `/admin/lucky-draws`, icon: Gift, title: 'Lucky Draws', type: 'link' },
            { path: `/admin/deposit`, icon: ArrowDownCircle, badge: "badge badge-danger", depositNoti: true, title: 'Deposit', type: 'link' },
            { path: `/admin/withdraw`, icon: ArrowUpCircle, badge: "badge badge-danger", withdrawNoti: true, title: 'Withdraw', type: 'link' },
            {
                title: 'Manual Cashier', icon: Repeat, path: `#`, type: 'sub', children: [
                    { path: `/admin/manual-deposit`, type: 'link', title: 'Manual Deposit' },
                    { path: `/admin/manual-withdraw`, type: 'link', title: 'Manual Withdraw' },
                ]
            },
            { path: `/admin/payment-providers`, icon: DollarSign, title: 'Payment Providers', type: 'link' },
            { path: `/admin/transaction-history`, icon: Clock, title: 'Trans History', type: 'link' },
            { path: `/admin/balance-transfer`, icon: Repeat, title: 'Balance Trans', type: 'link' },
            { path: `/admin/point-trans-history`, icon: Star, title: 'Point Trans', type: 'link' },
            {
                title: 'Reports', icon: Folder, path: `#`, type: 'sub', children: [
                    { path: `/admin/reports/provider`, type: 'link', title: 'Provider Report ' },
                    { path: `/admin/reports/users`, type: 'link', title: 'Users Report' },
                    { path: `/admin/reports/streamers`, type: 'link', title: 'Streamers Report' },
                    { path: `/admin/reports/agents`, type: 'link', title: 'Agents Report' },
                    { path: `/admin/reports/deposit-withdraw`, type: 'link', title: 'Deposit Withdraw Report' },
                ]
            },
            {
                title: 'Sport Betting', icon: Dribbble, path: `#`, type: 'sub', children: [
                    { path: `/admin/betting/betting-body`, type: 'link', title: 'Betting Body' },
                    { path: `/admin/betting/betting-parlay`, type: 'link', title: 'Betting Parlay' },
                ]
            },
            // {
            //     title: 'Change Password', icon: Key, path: `#`, type: 'sub', children: [
            //         { path: `/admin/change-self-password`, type: 'link', title: 'Change Self Password' },
            //     ]
            // },
            { path: `/admin/change-self-password`, icon: Key, title: 'Change Self Password', type: 'link' },
            { path: `/admin/games-betting`, icon: Monitor, title: 'Games Betting', type: 'link' },

            { path: `/admin/providers`, icon: UserPlus, title: 'Providers', type: 'link' },
            { path: `/admin/game-types`, icon: Type, title: 'Game Types', type: 'link' },
            // { path: `/admin/game-connect`, icon: GitPullRequest, title: 'Game Connect', type: 'link' },
            // { path: `/admin/games`, icon: Monitor, title: 'Games', type: 'link' },
            { path: `/admin/games-list`, icon: List, title: 'Games List', type: 'link' },

            { path: `/admin/banner`, icon: Image, title: 'Banner', type: 'link' },
            { path: `/admin/caption`, icon: PenTool, title: 'Banner Caption', type: 'link' },
            { path: `/admin/notification`, icon: Mail, title: 'Notification', type: 'link' },
            {
                title: 'Promotions', icon: Gift, path: `#`, badge: "badge badge-danger", promotionNoti: true, type: 'sub', children: [
                    { path: `/admin/promotions`, type: 'link', title: 'Create Promotion' },
                    { path: `/admin/promotions/users`, type: 'link', title: 'Promotion users' },
                ]
            },
            { path: `/admin/popup`, icon: Image, title: 'Ads Popup', type: 'link' },
            // { path: `/admin/contact`, icon: Phone, title: 'Contact Us', type: 'link' },
        ]
    },
]