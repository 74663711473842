import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import {
  deleteMethod,
  deleteMethodService,
  getMethod,
  getMethodService,
  postMethodFormData,
  postMethodService,
} from "../api";
import Breadcrumbs from "../layout/breadcrumb";
import Layout from "./Layout";
import SweetAlert from "sweetalert2";
import Loader from "../layout/loader/Loader";
import TableScroll from "../utils/TableScroll";
import {NOTI_CREATE_MESSAGES_API, NOTI_MESSAGES_API, NOTI_SEND_NOTI_API } from "../api/routes";
import Cookies from 'js-cookie';

const Notification = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [createNoti, setCreateNoti] = useState(false);
  const [title,setTitle] = useState("");
  const [description,setDescription] = useState("");

  const [titleValidate,setTitleValidate] = useState("");
  const [descriptionValidate,setDescriptionValidate] = useState("");


  const fetchMessages = async () => {
    setLoading(true);
    const response = await getMethodService(NOTI_MESSAGES_API);
    if(response){
        setLoading(false);
      if(response === "Unauthenticated"){
        localStorage.removeItem("y_g_n_d_a");
        Cookies.remove('token')
        window.location.reload();
        return;
      }
      if(response.status === "success"){
        setData(response?.data);
      };
    };
  };
  useEffect(()=>{
    fetchMessages();
  },[]);


  const createOpenModal = () => {
    setCreateNoti(!createNoti);
  };

  const createSubmitHandler = async (e) => {
    e.preventDefault();
    if(!title){
        return setTitleValidate("Title field is required!");
    };
    if(!description){
        return setDescriptionValidate("Description field is required!");
    };
    setLoading(true);
    let data = {
        title,
        detail: description,
    }

    const res = await postMethodService(
        NOTI_CREATE_MESSAGES_API,data);
    res && setLoading(false);
    if (res?.status === "success") {
      setCreateNoti(false);
      SweetAlert.fire({
        title: "Success!",
        text: "You has been create",
        icon: "success",
        width: 300,
      });
      fetchMessages();
      return;
    } else {
      SweetAlert.fire({
        icon: "error",
        width: 300,
        title: 'Sorry!',
        text: (res?.field ?? '') + ' ' + (res?.message ?? "Something wrong, Try again!"),
      });
      return;
    }
  };



  const deleteHandler = async (id) => {
    SweetAlert.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      width: 300,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        (async () => {
          const res = await deleteMethodService(`${NOTI_MESSAGES_API}/${id}`);
          res && setLoading(false);
          if (res?.status === "success") {
            SweetAlert.fire({
              title: "Success!",
              text: "You has been deleted.",
              icon: "success",
              width: 300,
            });
            fetchMessages();
            return;
          } else {
            SweetAlert.fire({
              title: "Sorry!",
              text: res?.message ?? "Something wrong, Try again!",
              icon: "error",
              width: 300,
            });
            return;
          }
        })();
      }
    });
  };

  const sendNotiHandler = async (id) => {
    setLoading(true);
    const response = await getMethodService(NOTI_SEND_NOTI_API(id)); // Call Auth api
    if(response){
      setLoading(false);
      if(response === "Unauthenticated"){
        localStorage.removeItem("y_g_n_d_a");
        Cookies.remove('token')
        window.location.reload();
        return;
      }
      if(response.status === "success"){
        SweetAlert.fire({
            title: "Success!",
            text: "Notification have been send.",
            icon: "success",
            width: 300,
        });
      };
    };
  };
  
  return (
    <>
      <Layout>
        <Breadcrumbs title="Notification" />
        <Container fluid={true}>
        {loading && <Loader />}
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader className="py-4 d-flex justify-content-end">
                  <Button
                    color=""
                    className="text-white"
                    style={{ backgroundColor: "#001753" }}
                    onClick={createOpenModal}
                  >
                    Create
                  </Button>
                </CardHeader>
                <CardBody className="position-relative">
                  <TableScroll>
                    <Table className="table-border-vertical">
                      <thead>
                        <tr className="bg-dark">
                          <th className="text-center text-white" scope="col">
                            <b>No.</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>Title</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>Description</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>Action</b>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.length > 0 &&
                          data?.map((el, i) => (
                            <tr key={i} className="align-middle">
                              <th className="text-center" scope="row">{`${
                                i + 1
                              }.`}</th>
                              <td className="text-center">
                                 {el?.title}
                              </td>
                              <td className="text-center">
                                 {el?.detail}
                              </td>
                              <td className="text-center">
                                <div>
                                  <span
                                    onClick={() => sendNotiHandler((el.id))}
                                  >
                                    <i
                                      className="fa fa-send"
                                      style={{
                                        width: 35,
                                        fontSize: 18,
                                        padding: 11,
                                        color: "rgb(40, 167, 69)",
                                        cursor: "pointer",
                                      }}
                                    ></i>
                                  </span>
                                  <span
                                    onClick={() => {
                                      deleteHandler(el.id);
                                    }}
                                  >
                                    <i
                                      className="fa fa-trash"
                                      style={{
                                        width: 35,
                                        fontSize: 18,
                                        padding: 11,
                                        color: "#dc3545",
                                        cursor: "pointer",
                                      }}
                                    ></i>
                                  </span>
                                </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </TableScroll>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Layout>
      {
        <Modal isOpen={createNoti} toggle={createOpenModal} centered>
          <Form onSubmit={createSubmitHandler}>
            <ModalHeader toggle={createOpenModal}>Create Notification</ModalHeader>
            <ModalBody>
              <FormGroup>
                <Label htmlFor="">Title</Label>
                <Input
                  value={title}
                  className="form-control"
                  type="text"
                  placeholder="Title"
                  onChange={(e) => {
                    setTitle(e.target.value);
                    setTitleValidate("");
                  }}
                />
                {titleValidate && (
                  <div className="invalid-feedback d-block">
                    {titleValidate}
                  </div>
                )}
              </FormGroup>
              <FormGroup>
                <Label htmlFor="">Description</Label>
                <Input
                  className="form-control"
                  value={description}
                  type="textarea"
                  rows={5}
                  placeholder="Description"
                  onChange={(e) => {
                    setDescription(e.target.value);
                    setDescriptionValidate("");
                  }}
                  style={{resize: 'none'}}
                />
                {descriptionValidate && (
                  <div className="invalid-feedback d-block">
                    {descriptionValidate}
                  </div>
                )}
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={createOpenModal}>
                Close
              </Button>
              <Button color="primary" type="submit">
                Save
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      }
    </>
  );
};

export default Notification;
